import uiButtonMain from '@/components/UI/ui-button-main.vue'
import uiCustomInput from '@/components/UI/ui-custom-input.vue'
import uiCustomInputRadio from '@/components/UI/ui-custom-input-radio.vue'
import uiCustomInputFile from "@/components/UI/ui-custom-input-file.vue"

import uiArrIcon from '@/components/UI/temp/ui-arrow-filled-icon.vue' // TEMP
import uiTest from '@/components/UI/temp/temp-comp-test.vue' // TEMP


export default [
  uiButtonMain,
  uiCustomInput,
  uiCustomInputRadio,
  uiCustomInputFile,
  uiArrIcon, // TEMP
  uiTest, // TEMP
]